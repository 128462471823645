@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/assets/fonts/picnic/PicNic-Regular.otf */

html, body {
  height: 100%;
  margin: 0;
  background: linear-gradient(to right, #ebf8ff, #faf5ff);
  background-color: #fff; /* Fallback color for older browsers */
  font-family: 'Sans', sans-serif;
}

body.dark-mode {
  background: linear-gradient(to right, #1a202c, #2d3748);
  background-color: #000; /* Fallback color for older browsers */
}
@font-face {
  font-family: "PicNic-Regular";
  src: url("./assets/fonts/picnic/PicNic-Regular.otf") format("opentype");
}

@keyframes toast {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  10%, 90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.animate-toast {
  animation: toast 3s ease-in-out;
}


/* 
@font-face {
  font-family: "RGOBold";
  src: url("./assets/fonts/MONO-RGOMODULAR-Bold.otf") format("opentype");
}

@font-face {
  font-family: "RGORegular";
  src: url("./assets/fonts/MONO-RGOMODULAR-Regular.otf") format("opentype");
}

@font-face {
  font-family: "RGOSemiBold";
  src: url("./assets/fonts/MONO-RGOMODULAR-SemiBold.otf") format("opentype");
} */

/* src/assets/fonts/scope/ScopeTrial-Bold.otf */

@font-face {
  font-family: "ScopeTrial-Bold";
  src: url("./assets/fonts/scope/ScopeTrial-Bold.otf") format("opentype");
}

/* src/assets/fonts/scope/ScopeTrial-BoldCondensed.otf */

@font-face {
  font-family: "ScopeTrial-BoldCondensed";
  src: url("./assets/fonts/scope/ScopeTrial-BoldCondensed.otf")
    format("opentype");
}

/* src/assets/fonts/scope/ScopeTrial-BoldExpanded.otf */

@font-face {
  font-family: "ScopeTrial-BoldExpanded";
  src: url("./assets/fonts/scope/ScopeTrial-BoldExpanded.otf")
    format("opentype");
}

/* src/assets/fonts/scope/ScopeTrial-Light.otf */

@font-face {
  font-family: "ScopeTrial-Light";
  src: url("./assets/fonts/scope/ScopeTrial-Light.otf") format("opentype");
}

/* src/assets/fonts/scope/ScopeTrial-LightCondensed.otf */

@font-face {
  font-family: "ScopeTrial-LightCondensed";
  src: url("./assets/fonts/scope/ScopeTrial-LightCondensed.otf")
    format("opentype");
}

/* src/assets/fonts/scope/ScopeTrial-LightExpanded.otf */

@font-face {
  font-family: "ScopeTrial-LightExpanded";
  src: url("./assets/fonts/scope/ScopeTrial-LightExpanded.otf")
    format("opentype");
}

/* src/assets/fonts/scope/ScopeTrial-Medium.otf */

@font-face {
  font-family: "ScopeTrial-Medium";
  src: url("./assets/fonts/scope/ScopeTrial-Medium.otf") format("opentype");
}

/* src/assets/fonts/scope/ScopeTrial-MediumCondensed.otf */

@font-face {
  font-family: "ScopeTrial-MediumCondensed";
  src: url("./assets/fonts/scope/ScopeTrial-MediumCondensed.otf")
    format("opentype");
}

/* src/assets/fonts/scope/ScopeTrial-MediumExpanded.otf */

@font-face {
  font-family: "ScopeTrial-MediumExpanded";
  src: url("./assets/fonts/scope/ScopeTrial-MediumExpanded.otf")
    format("opentype");
}
/* src/assets/fonts/scope/ScopeTrial-Variable.ttf */

@font-face {
  font-family: "ScopeTrial-Variable";
  src: url("./assets/fonts/scope/ScopeTrial-Variable.ttf") format("truetype");
}

.img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

/* src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-black-italic.woff2 */

@font-face {
  font-family: "Test The Future Black Italic";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-black-italic.woff2")
    format("woff2");
}

/* src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-black.woff2 */

@font-face {
  font-family: "Test The Future Black";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-black.woff2")
    format("woff2");
}

/* access alternates aka font variants */

/* src/assets/fonts/klim/Test The Future Collection/Test The Future Mono/test-the-future-mono-regular.woff2 */

@font-face {
  font-family: "Test The Future Mono Regular";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future Mono/test-the-future-mono-regular.woff2")
    format("woff2");
}

/* src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-regular.woff2
         */

@font-face {
  font-family: "Test The Future Regular";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-regular.woff2")
    format("woff2");
}

/* medium */
/* and medium italic */

@font-face {
  font-family: "Test The Future Medium";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-medium.woff2")
    format("woff2");
}

@font-face {
  font-family: "Test The Future Medium Italic";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-medium-italic.woff2")
    format("woff2");
}

/* medium */
/* and medium italic */
@font-face {
  font-family: "Test Untitled Sans Black";
  src: url("./assets/fonts/klim/Test Untitled Collection/Test Untitled Sans/test-untitled-sans-black.woff2")
    format("woff2");
}

@font-face {
  font-family: "Test Untitled Sans Black Italic";
  src: url("./assets/fonts/klim/Test Untitled Collection/Test Untitled Sans/test-untitled-sans-black-italic.woff2")
    format("woff2");
}

@font-face {
  font-family: "Test Untitled Sans Bold";
  src: url("./assets/fonts/klim/Test Untitled Collection/Test Untitled Sans/test-untitled-sans-bold.woff2")
    format("woff2");
}

@font-face {
  font-family: "Test Untitled Sans Bold Italic";
  src: url("./assets/fonts/klim/Test Untitled Collection/Test Untitled Sans/test-untitled-sans-bold-italic.woff2")
    format("woff2");
}

@font-face {
  font-family: "Test Untitled Sans Light";
  src: url("./assets/fonts/klim/Test Untitled Collection/Test Untitled Sans/test-untitled-sans-light.woff2")
    format("woff2");
}

@font-face {
  font-family: "Test Untitled Sans Light Italic";
  src: url("./assets/fonts/klim/Test Untitled Collection/Test Untitled Sans/test-untitled-sans-light-italic.woff2")
    format("woff2");
}

@font-face {
  font-family: "Test Untitled Sans Medium";
  src: url("./assets/fonts/klim/Test Untitled Collection/Test Untitled Sans/test-untitled-sans-medium.woff2")
    format("woff2");
}

@font-face {
  font-family: "Test Untitled Sans Medium Italic";
  src: url("./assets/fonts/klim/Test Untitled Collection/Test Untitled Sans/test-untitled-sans-medium-italic.woff2")
    format("woff2");
}

@font-face {
  font-family: "Test Untitled Sans Regular";
  src: url("./assets/fonts/klim/Test Untitled Collection/Test Untitled Sans/test-untitled-sans-regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Test Untitled Sans Regular Italic";
  src: url("./assets/fonts/klim/Test Untitled Collection/Test Untitled Sans/test-untitled-sans-regular-italic.woff2")
    format("woff2");
}


/* 

        src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-bold-italic.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-bold.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-extralight-italic.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-extralight.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-italic.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-light-italic.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-light.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-medium-italic.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-medium.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-regular.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-thin-italic.woff2
src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-thin.woff2 */

/* src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-bold-italic.woff2 */

/* src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-bold.woff2 */

@font-face {
  font-family: "Test The Future Bold";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-bold.woff2")
    format("woff2");
}

/* src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-extralight-italic.woff2 */

@font-face {
  font-family: "Test The Future Extra Light Italic";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-extralight-italic.woff2")
    format("woff2");
}

/* src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-extralight.woff2 */

@font-face {
  font-family: "Test The Future Extra Light";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-extralight.woff2")
    format("woff2");
}

/* src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-italic.woff2 */

@font-face {
  font-family: "Test The Future Italic";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-italic.woff2")
    format("woff2");
}

/* src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-light-italic.woff2 */

@font-face {
  font-family: "Test The Future Light Italic";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-light-italic.woff2")
    format("woff2");
}

/* src/assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-light.woff2 */

@font-face {
  font-family: "Test The Future Light";
  src: url("./assets/fonts/klim/Test The Future Collection/Test The Future/test-the-future-light.woff2")
    format("woff2");
}
@font-feature-values Font One {
  @swash {
    @styleset {
      @style {
        font-family: "Test The Future Black";
        font-weight: 400;
        font-style: normal;
      }
      @style {
        font-family: "Test The Future Black Italic";
        font-weight: 400;
        font-style: italic;
      }
    }
  }
}


/* src/assets/fonts/klim/Test Söhne Collection/Test Söhne Breit/test-soehne-breit-extrafett.woff2 */

@font-face {
  font-family: "Test Söhne Breit Extrafett";
  src: url("./assets/fonts/klim/Test Söhne Collection/Test Söhne Breit/test-soehne-breit-extrafett.woff2")
    format("woff2");
}

/* src/assets/fonts/klim/Test Söhne Collection/Test Söhne Breit/test-soehne-breit-fett.woff2 */

@font-face {
  font-family: "Test Söhne Breit Fett";
  src: url("./assets/fonts/klim/Test Söhne Collection/Test Söhne Breit/test-soehne-breit-fett.woff2")
    format("woff2");
}

