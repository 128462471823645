/* firebaseui-dark-mode.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

.firebaseui-styled .firebaseui-container {
  @apply dark:bg-black dark:text-white font-mono;
}

.firebaseui-styled .firebaseui-card-content {
  @apply bg-black border-2 border-white text-white font-mono;
}

.firebaseui-styled .firebaseui-button {
  @apply bg-gray-700 text-white border-gray-600 font-sans;
}

.firebaseui-styled .firebaseui-button:hover {
  @apply bg-gray-600;
}

.firebaseui-styled .firebaseui-input {
  @apply bg-gray-800 text-white border-gray-600 font-sans;
}

.firebaseui-styled .firebaseui-title,
.firebaseui-styled .firebaseui-subtitle,
.firebaseui-styled .firebaseui-text {
  @apply text-white font-sans;
}

/* Add custom font-mono to firebaseui-title */
.firebaseui-styled .firebaseui-title {
  @apply text-white font-mono;
}

.firebaseui-styled .firebaseui-idp-button {
  @apply bg-gray-200 text-white border-gray-600 font-sans;
}

.firebaseui-styled .firebaseui-idp-button:hover {
  @apply bg-gray-200;
}

.firebaseui-styled .firebaseui-list-item {
  @apply text-white border-gray-200 font-sans;
}

.firebaseui-styled .firebaseui-recaptcha-container {
  @apply bg-gray-200 text-white font-sans;
}

.firebaseui-styled .firebaseui-input::placeholder {
  @apply placeholder-white; /* Default placeholder color */
}

/* Dark mode specific styles */
.dark .firebaseui-styled .firebaseui-input::placeholder {
  color: text-white; /* Change placeholder text color to white in dark mode */
}

.firebaseui-styled .firebaseui-input {
  @apply bg-gray-800 text-gray-300 border-gray-200 placeholder-gray-500 font-sans;
}

/* Custom style for the country code text (+1) */
.firebaseui-id-country-selector-code {
  color: #ffffff !important; /* Change color of the country code text to white */
}

/* Custom style for the "VERIFY" button */
button.firebaseui-id-submit {
  @apply bg-white text-black font-mono;
  border: 1px solid #3b82f6; /* Optional: Add a border for better visibility */
}

button.firebaseui-id-submit:hover {
  @apply bg-gray-300;
}

/* Custom style for the text field to be more visible in dark mode */
.firebaseui-textfield,


/* Change the floating label color */
.mdl-textfield__label {
  color: #ff6868 !important; /* Set the floating label color to white */
}

/* Adjust the textfield input color */
.mdl-textfield__input {
  color: #ffffff !important; /* Set the input text color to white */
}

