.banner {
  max-width: 825px;
  margin: 0 auto;
  padding: 0 22px;
  font-size: 14px;
  background: white;
  color: #6a7c94;
  border-radius: 22px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}
