
@keyframes unix-spin {
    0%, 100% {
      content: '/';
    }
    25% {
      content: '-';
    }
    50% {
      content: '\\'; /* Escape the backslash */
    }
    75% {
      content: '|';
    }
  }
  
  .unix-spin::after {
    display: inline-block;
    animation: unix-spin 1s steps(4, end) infinite;
    content: '/';
  }
  
  